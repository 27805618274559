@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
    font-family: "Montserrat", sans-serif;
}
.text-center {
    text-align: center;
}

body {
    margin: 0;
    background-color: #d5dee1;
    background-image: linear-gradient(315deg, #ffffff 0%, #e3e3ec 74%);
}

.mt-15 {
    margin-top: 15px;
}

img {
    height: auto;
    max-width: 100%;
}

body {
    font-family: "Montserrat", sans-serif;
}

.div-self {
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: auto;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media (min-width: 600px) {
    .div-self {
        max-width: 600px;
    }

    .div-self {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.card-s {
    background-color: #cacbdb;
    padding: 40px 60px;
    border-radius: 25px;
}

.card-header-s {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.flex-logo {
    display: flex;
    flex: 0 0 auto;
    margin-right: 16px;
}

.logo-div {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: "Montserrat", sans-serif;

    font-size: 1.25rem;
    line-height: 1;
    /* border-radius: 50%; */
    overflow: hidden;
    user-select: none;
    width: 60px;
    height: 60px;
}

.company-logo {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
}

.d-flex-auto {
    flex: 1 1 auto;
}

.header-text-s {
    color: rgb(38, 37, 37);
    font-weight: 600;
    font-size: 25px;
    line-height: 22px;
}

.banner-img {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    object-fit: cover;
    height: 170px;
    border: 2px solid white;
    border-radius: 20px;
}

.card-body-s {
    padding: 16px;
}

.para-s {
    color: #1b1919;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}
.contract-div {
    color: rgb(54, 52, 52);
    text-align: center;
    background: #b6bde1;
    border-radius: 8px;
    padding: 5px 25px;
    word-wrap: break-word;
}
.contract-font {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}

/* .contract-text {
    margin: 0px;
    color: rgba(0, 0, 0, 0.6);
    padding: 16px;
} */

.claim-h {
    font-weight: 800;
    font-size: 29px;
    line-height: 59px;
    letter-spacing: -0.017em;
    text-transform: capitalize;
    text-align: center;
    color: #437ef4;
    margin: 15px;
}

.hr-d {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.card-footer-s {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px;
    justify-content: center;
}

.btn-claim {
    border-radius: 20px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 34px;
    background-color: #5389f4;
    border: unset;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;

    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    color: rgb(15, 14, 14);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.btn-claim:hover {
    background-color: #a9b1df;
}
.span-d {
    display: inherit;
    margin-right: 8px;
    margin-left: -4px;
}

.svg-d {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}

.span-d > :nth-of-type(1) {
    font-size: 20px;
}

@media (max-width: 768px) {
    .card-s {
        padding: 35px 30px;
    }
}
